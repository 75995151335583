export default {
	inserted(el, binding) {
		const view = el.querySelector('.el-scrollbar__wrap')
		const autoScroll = function() {
			if ((view.scrollTop + view.clientHeight) < view.scrollHeight) {
				const total = 48  // 总共48像素
				let start = 0
				const step = total / 300 // 1毫秒步进的像素
				let scrollHeight = 0     // 单次动画滚动了总高度, 不能大于 total
				let currentTop = view.scrollTop

				const timer = requestAnimationFrame(function autoScroll(timestamp) {
					if (!start) start = timestamp
					// 过去了多少毫秒
					const elapsed = timestamp - start
					// 重置初始值
					start = timestamp
					// 一帧滑动了多少像素
					const scrollTop = elapsed * step
					// 计算单次动画共滑动了多少像素
					scrollHeight = Math.min(scrollHeight + scrollTop, total)
					// 更新每一帧元素的滚动距离
					view.scrollTop = currentTop + scrollHeight
					if (scrollHeight < total) {
						requestAnimationFrame(autoScroll)
					} else {
						currentTop = view.scrollTop
						cancelAnimationFrame(timer)
					}
				})
			} else {
				view.scrollTop = 0
			}
		}
		el.timer2 = setInterval(autoScroll, 3000)

		el.enterEvent = function() {
				clearInterval(el.timer2)
				el.timer2 = null
		}
		el.leaveEvent = function() {
			el.timer2 = setInterval(autoScroll, 3000)
		}
		view.addEventListener('mouseenter', el.enterEvent)
		view.addEventListener('mouseleave', el.leaveEvent)
		// console.log('inserted')
	},

	unbind(el) {
		clearTimeout(el.timer2)
		el.timer2 = null
		const view = el.querySelector('.el-scrollbar__wrap')
		view.removeEventListener('mouseenter', el.enterEvent)
		view.removeEventListener('mouseenter', el.leaveEvent)
		// console.log('unbind')
	},
}
