//服务器地址
const HTTP_URL = 'https://jchelper.dindong.cn'
const HTTP_URL_DEV = 'https://jchelper-dev.dindong.cn'
const HTTP_URL_NATIVE = 'https://jchelper.zoomyoung.cn'
// const HTTP_URL_NATIVE = 'http://192.168.0.112:8000/'

// const API_BASE_URL = HTTP_URL_DEV + '/v2/' // 测试服
// const API_BASE_URL = HTTP_URL + '/v2/'     // 正式服
const API_BASE_URL = HTTP_URL_NATIVE + '/api/'

const OSS_PREFIX_URL = 'https://rzkeji.oss-cn-shenzhen.aliyuncs.com/web/jchelper2021/dingbian/user/'

export {
  HTTP_URL,
  API_BASE_URL,
	OSS_PREFIX_URL,
}
