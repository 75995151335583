import {Auth} from '@/utils/auth'
import router from '@/router'
import {logout, getUserInfo,} from '@/api'
import { Notification } from 'element-ui'

const getDefaultState = () => {
	return {
		token: Auth.getToken(),
		user_name: '',
	}
}
const state = getDefaultState()

const mutations = {
	// 设置token
	SET_TOKEN: (state) => {
		state.token = Auth.getToken()
	}
}

const actions = {

	// 普通登录
	login({ commit, state }, params) {
		return new Promise((resolve, reject) => {
			loginByPhone(params).then(async({ data }) => {
				if (data.level === 'success') {
					Auth.setToken(data.data)
					await this.dispatch('user/getUserInfo')
					Notification.success(data.message)
					resolve(data.data)
				} else {
					Notification.warning(data.message)
					reject(data.message)
					console.error(data.message)
				}
			}).catch(error => {
				reject(error)
			})
		})
	},

	// 获取管理员信息
	getUserInfo() {
		return new Promise((resolve, reject) => {
			getUserInfo().then(({data}) => {
				if (data.level === 'success') {
					this.commit('user/SET_TOKEN')
					resolve(data.data)
				}
			}).catch(error => {
				reject(error)
			})
		})
	},



	// 退出登录
	logout({ commit, state }) {
		return new Promise((resolve, reject) => {
			logout().then(() => {
				Auth.removeToken()
				this.commit('user/SET_TOKEN')
				this.commit('user/SET_USER_NAME', '')
				Notification.success('退出成功 !')
				resolve()
			}).catch(error => {
				reject(error)
			})
		})
	},

}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
