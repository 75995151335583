import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/layout/layout'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		redirect: '/rationalize'
	},

	{
		path: '*',
		redirect: '/404'
	},

	{
		path: '/404',
		component: () => import('@/views/unknown/unknown')
	},

	{ // 首页
		path: '/rationalize',
		component: Layout,
		children: [
			{
				path: '',
				name: 'rationalize',
				component: () => import('@/views/rationalize/rationalize')
			}
		]
	},

	{ // 规划明细
		path: '/plans',
		component: Layout,
		children: [
			{
				path: '',
				name: 'plans',
				component: () => import('@/views/plans/plans')
			}
		]
	},

	{ // 预约轮候
		path: '/appointment-waiting',
		component: Layout,
		children: [
			{
				path: '',
				name: 'appointment-waiting',
				component: () => import('@/views/appointment-waiting/appointment-waiting')
			}
		]
	},

	{ // 登录页
		path: '/login',
		component: Layout,
		children: [
			{
				path: '',
				name: 'login',
				component: () => import('@/views/login/login')
			}
		]
	},

	{
		path: '/map',
		component: () => import('@/views/rationalize/map.vue')
	}
]

const router = new VueRouter({
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})

export default router
