
const getDefaultState = () => {
	return {
		activity_id: '',
		isActivityToLogin: false,
		isTapEntToLogin: false,
	}
}

const state = getDefaultState()

const mutations = {
	// 保存活动id
	SET_ACTIVITY_ID(state, id = '') {
		state.activity_id = id
	},

	// 是否从活动详情页跳到登录页
	SET_IS_ACTIVITY_TO_LOGIN(state, bool) {
		state.isActivityToLogin = bool
	},

	// 是否点击"我是组织"按钮跳到登录页
	SET_IS_TAP_ENT_TO_LOGIN(state, bool) {
		state.isTapEntToLogin = bool
	},
}

const actions = {}

export default {
	namespaced: true,
	state,
	mutations,
	actions
}
