import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {scrollBoard} from '@jiaminghi/data-view'
import ElementUI from 'element-ui'
import { VueJsonp } from 'vue-jsonp'
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'

// import '@/icons'
// import '@/router/permission'

import * as api from './api'
import {joinOSSUrl} from "@/utils/utils";
import scroll from '@/directives/scroll'
import 'normalize.css/normalize.css'
import '@/styles/index.scss'
import countTo from 'vue-count-to'


Vue.config.productionTip = false
Vue.component('elImageViewer', ElImageViewer)
Vue.use(ElementUI)
Vue.use(VueJsonp)
Vue.use(scrollBoard)
Vue.directive('scroll', scroll)
Vue.component('countTo', countTo);
Vue.prototype.$api = api;
Vue.prototype.$OSS_URL = joinOSSUrl

window._AMapSecurityConfig = { securityJsCode: 'c3fcdc9b48f01845f26af6baa529fc08' }

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
