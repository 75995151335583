import {OSS_PREFIX_URL} from "@/config/config";

export function isExternal(path) {
	return /^(https?:|mailto:|tel:)/.test(path)
}

// 名字太长用省略号
export function formatEllipsisText(text, count) {
	if (typeof text !== 'string' || text === '') {
		return ''
	}
	if (text.length > 12) {
		return text.substring(0, count) + '...'
	}
	return text
}

// 判断当前设备是pc端还是移动端
export function isMobile() {
	const userAgent = navigator.userAgent.toLowerCase();
	const mobileKeywords = ['android', 'iphone', 'ipod', 'windows phone', 'harmony', 'hm'];

	for (let i = 0; i < mobileKeywords.length; i++) {
		if (userAgent.indexOf(mobileKeywords[i]) > -1) {
			return true; // 移动端
		}
	}

	return false; // PC端
}

export function sliceDateTime(dateTimeStr) {
	if (typeof dateTimeStr !== 'string') return ''
	return dateTimeStr.substring(0, 16)
}

export function objToParams(obj) {
	return Object.entries(obj)
		.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
		.join('&');
}

export function debounce(fn, delay) {
	let timer = null
	return function(...args) {
		if (timer) clearTimeout(timer)
		timer = setTimeout(() => {
			fn.apply(this, args)
		}, delay)
	}
}

export function joinOSSUrl(fileName, fileType='png') {
	if (typeof fileName !== 'string') return ''
	return `${OSS_PREFIX_URL}${fileName}.${fileType}`
}

export function substring(str, start=0, end) {
	if (typeof str !== "string") return "";
	return str.substring(start, end);
}
