import axios, {config, createService} from "@/utils/request.js";
import {API_BASE_URL} from '@/config/config'

//上传文件
export const UPLOAD_FILE = API_BASE_URL + 'file/upload-img'

/**
 *  普通用户登录注册
 */
// 手机号码登录注册
export const loginByPhone = params => axios.post("job/auth/loginByPhone", params);
// 发送验证码
export const sendVerificationCode = params => axios.post("job/auth/sendVerificationCode", params);
// 退出登录
export const logout = params => axios.get("job/auth/logout", {params});
// 获取用户信息
export const getUserInfo = params => axios.get("job/auth/getUserInfo", {params});
// 编辑用户信息
export const setUserInfo = params => axios.post("job/auth/setUserInfo", params);


// 搜索提示
export const addressSearchPromptList = (function () {
    const service = createService(config)
    return function (params, source) {
        return service.post('dingbian/addressSearchPromptList', params, {cancelToken: source.token || null})
    }
})()
// 查询地址范围内的所有店铺
export const searchInScopeShopList = (function () {
    const service = createService(config)
    return function (params, source) {
        return service.post('dingbian/searchInScopeShopList', params, {cancelToken: source.token || null})
    }
})()
// 地址搜索
export const addressSearchList = params => axios.post("dingbian/addressSearchList", params);
// 市场单元概览
export const getMarketUnitList = params => axios.post("dingbian/getMarketUnitList", params);
// 市场单元规划明细
export const getMarketUnitPlanningDetail = params => axios.post("dingbian/getMarketUnitPlanningDetail", params);
// 是否允许新办证
export const whetherAllowNewCert = params => axios.post("dingbian/whetherAllowNewCert", params);
// 获取当前位置所在的社区
export const getCurrentLocationMarketUnit = params => axios.post("dingbian/getCurrentLocationMarketUnit", params);
//获取定边县点聚合数据
export const getMarketUnitLocationList = params => axios.post("dingbian/getMarketUnitLocationList", params);
// 轮候榜
export const getLicenceNewIntentionList = params => axios.post("dingbian/getLicenceNewIntentionList", params);
// 获取新办证意向二维码
export const getLicenceNewIntentionUrl = params => axios.post("dingbian/getLicenceNewIntentionUrl", params);
// 获取除定边县之外的所有店铺
export const getTownshipAllShop = params => axios.post("dingbian/getTownshipAllShop", params);
//获取乡镇范围内的店铺经纬度
export const getTownshipSubordinateShop = params => axios.post("dingbian/getTownshipSubordinateShop", params);
// 获取市场单元预约池
export const getMarketUnitAppointmentPool = params => axios.post("dingbian/getMarketUnitAppointmentPool", params);
// 获取市场单元轮候池
export const getMarketUnitWaitingPool = params => axios.post("dingbian/getMarketUnitWaitingPool", params);
// 获取市场单元
export const getWaitAppointmentMarketUnit = params => axios.post("dingbian/getWaitAppointmentMarketUnit", params);
